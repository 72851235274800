<template>
  <div class="message-container">
   短信设置
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang='scss'>
.message-container {
}
</style>